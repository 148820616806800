
import QuoteRequestFilter from 'tradingmate-modules/src/filters/QuoteRequestFilter'
import QuoteRequest from 'tradingmate-modules/src/models/api/quotes/QuoteRequest'
import QuoteRequestStatus from 'tradingmate-modules/src/models/api/quotes/QuoteReqeuestStatus'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/actions/Modal.vue'

@Component({
  components: {
    Modal
  }
})

export default class QuoteRequestsDetails extends Vue {
  private quoteRequest: QuoteRequest | null = null;
  private modalActive = false;

  private httpError: HttpError | null = null;
  private isLoading = false

  mounted (): void {
    this.getQuote()
  }

  private isoToQuoteDate (iso: string): string {
    return (new Date(iso)).toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })
  }

  getQuote (): void {
    const id = this.$route.params.id
    this.isLoading = true
    Services.API.Quotes.GetQuoteRequest(id, new QuoteRequestFilter({
      BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId
    }))
      .then((returnData) => {
        this.quoteRequest = returnData
        if (this.quoteRequest.Status === QuoteRequestStatus.Unread) {
          this.quoteRequest.Status = QuoteRequestStatus.Read
          this.updateQuoteRequest()
        }
      }).catch((error) => { this.httpError = error })
      .finally(() => { this.isLoading = false })
  }

  updateQuoteRequest (): void {
    if (!this.quoteRequest) return
    const id = this.$route.params.id
    Services.API.Quotes.UpdateQuoteRequest(id, this.quoteRequest, new QuoteRequestFilter({
      BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId
    })).then((quoteRequest) => {
      if (quoteRequest?.Status === QuoteRequestStatus.Deleted) { this.$router.push('/quote-requests') }
    }).catch((error) => { this.httpError = error })
  }

  deleteQuoteRequestInMember (): void {
    if (!this.quoteRequest) return
    this.quoteRequest.Status = QuoteRequestStatus.Deleted
    this.updateQuoteRequest()
  }
}
